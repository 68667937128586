<template>
  <div id="admin-update" class="admin-contents">
    <div class="admin-contents-title admin-update-title">更新日志</div>
    <div class="admin-contents-wrap admin-update-wrap">
      <div class="admin-update-editor-wrap">
        <mavon-editor
          class="admin-update-editor"
          v-model="updateLog"/>
      </div>
    </div>
    <div class="admin-update-button">
      <div class="admin-update-botton-group">
        <el-button type="success" plain @click="submit">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'admin-update',
  data () {
    return {
      updateLog: '',
      originLog: ''
    }
  },
  beforeRouteLeave: function (to, from, next) {
    if (!this.isSaveLog()) {
      const answer = window.confirm('您有改动未保存，是否放弃修改?')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  methods: {
    submit () {
      this.$auth_http({
        url: this.$api.ADMIN_UPDATE_LOG_URL,
        method: 'POST',
        data: {
          contents: this.updateLog
        }
      }).then(response => {
        if (response.status === 'success') {
          this.$message({
            message: '更新日志保存成功！',
            type: 'success'
          })
          this.copyUpdateLog()
        } else {
          this.$message.error('更新日志保存失败，' + response.message + '，请刷新')
        }
      }).catch(error => {
        this.$message.error('更新日志保存失败，' + error.toString() + '，请重试')
      })
    },
    getUpdateLog () {
      this.$auth_http({
        url: this.$api.ADMIN_UPDATE_LOG_URL,
        method: 'GET'
      }).then(response => {
        if (response.status === 'success') {
          this.updateLog = response.data.contents
          this.copyUpdateLog()
        } else {
          this.$message.error('更新日志获取失败，' + response.message + '，请刷新')
        }
      }).catch(error => {
        this.$message.error('更新日志获取失败，' + error.toString() + '，请重试')
      })
    },
    copyUpdateLog () {
      this.originLog = this.updateLog
    },
    isSaveLog () {
      return this.originLog === this.updateLog
    },
    beforeunloadHandler (e) {
      if (!this.isSaveLog()) {
        e = e || window.event
        // 兼容IE8和Firefox 4之前的版本
        if (e) {
          e.returnValue = '关闭提示'
        }
        // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return ('关闭提示')
      }
    }
  },
  created () {
    this.getUpdateLog()
  }
}
</script>

<style lang="stylus" scoped>
#admin-update
  .admin-update-wrap
    .admin-update-editor-wrap
      margin-bottom: 80px
      .admin-update-editor
        min-height: calc(100vh - 240px)
  .admin-update-button
    height: 60px
    width: 100%
    padding: 10px 20px
    position: fixed
    bottom: 0
    z-index: 1501
    border-top: 1px solid rgba(38, 42, 48, .1)
    background-color: #FFF
    .admin-update-button-group
      height: 100%
      padding: 10px 0
</style>
